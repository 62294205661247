body {
  font-family: 'Noto Serif', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content-container {
	flex-grow: 1;
	margin: 1ex 0;
	background-color: #eee;
	max-width: 100%;
}
@media (min-width: 600px) {
	.content-container {
		max-width: 600px;
	}
}

.title,h1,h2,h3,h4,h5,h6 {
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
}

.essay-footer {
	font-size: small;
}

a {
	text-decoration: none;
}

.small {
	font-size: small;
}

.paragraph,.content {
	overflow: auto;
}

a.logo-link {
	text-shadow: 0 0 4px;
}
a.logo-link:hover {
	text-shadow: 0 0 10px;
}

.root h2,h3,h4 {
	margin-bottom: 0;
}
.root h4 {
	margin-top: 3em;
}
.root .sect3:nth-child(2) {
	margin-top: -2em;
}
.root p {
	margin: 0;
}
.root li {
	margin-top: 1em;
}

.article h2 {
	margin: 0;
	font-size: 200%;
}
.article .date {
	font-size: small;
	color: #666;
	font-style: italic;
}
.article .date p {
	margin: 0;
}
.article hr {
	margin: 0.2em 0 0.5em 0;
	border: 0.5px dotted #aaa;
}
